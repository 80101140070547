// @klook/cookie-manage 使用文案

type Href = (pathname: string, language?: Data.Language, baseLink?: string) => string
type Trans = (key: string, ...args: any[]) => string

export default function ($t: Trans, $href: Href, isWhiteLabelEnv: boolean, lang: string) {
  const defObj = {
    22904: $t('22904'),
    22905: $t('22905', { MULTILANG_URL_PLACEHOLDER: $href('/cookiepolicy/') }),
    22906: $t('22906'),
    22908: $t('22908'),
    22909: $t('22909'),
    22910: $t('22910'),
    22911: $t('22911'),
    22912: $t('22912'),
    22914: $t('22914'),
    22915: $t('22915'),
    22916: $t('22916'),
    22917: $t('22917'),
    22918: $t('22918'),
    22919: $t('22919'),
    22922: $t('22922'),
    22923: $t('22923'),
    4132: $t('cookie.banner.desc', [lang === 'en' ? '' : `${lang}/`])
  }

  const wlObj = {
    22905: $t('22905', { MULTILANG_URL_PLACEHOLDER: $href('/conditions/?page=2') }),
    22914: $t('72296'),
    22917: $t('72297'),
    22919: $t('72298')
  }

  if (isWhiteLabelEnv) {
    return Object.assign({}, defObj, wlObj)
  }

  return defObj
}
