import { Component, Getter, State, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class FooterBase extends Vue {
  @Prop() hideFooterLanguage!: boolean
  @Prop() hideFooterCurrency!: boolean
  @State klook!: Data.Klook

  @Getter isGuestCheckout!: boolean

  get market() {
    return this.klook.market
  }

  get siteConfig() {
    return {
      ...this.klook.websiteConfig,
      ...this.klook.utilConfig
    }
  }

  getPartnerWebConfig(key: string) {
    return this.klook.affiliateConf?.partner_web_config?.[key]
  }

  get isPoweredVisible() {
    return this.getPartnerWebConfig('footer_powered_by_klook')
  }

  get isSocialCountVisible() {
    return this.getPartnerWebConfig('footer_third_party_link')
  }

  get isCountryVariable() {
    return this.siteConfig.footer_change_site
  }

  get isBusinessLicenseVisible() {
    return this.siteConfig.business_license
  }

  get isCnSite() {
    return this.klook.market === 'cn'
  }

  get isAboutUsLinkVisible() {
    return this.getPartnerWebConfig('footer_know_klook')
  }
}
