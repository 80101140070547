import Vue from 'vue'

const pageBus = new Vue({})

export default pageBus

/**
 * base
 * 1、get-settlement-info 请求结算信息
 * 2、submit-order 提交订单
 *
 * sms
 * 1、use-sms 使用短信验证码
 * 2、use-guest-captcha-verify 使用图形验证码
 *
 * request-error
 * 1、use-request-error 通用请求错误处理
 *
 * inhouse
 * 1、use-iht-pageview pageview埋点
 * 2、use-iht-payment-load 页面加载的时候的埋点
 * 3、use-iht-pay-btn 支付按钮的埋点
 * 4、use-iht-pay-confirm-btn 支付按钮校验成功，开始请求的埋点
 * 5、use-iht-generate-order 生单时候的埋点
 * 6、use-iht-request-error 接口错误埋点
 */
