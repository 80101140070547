import { Component, Getter, namespace, State, Vue, Prop } from 'nuxt-property-decorator'

const authModule = namespace('auth')

@Component
export default class HeaderBase extends Vue {
  @Prop() isMiniStyle!: boolean
  @Prop() hideHeaderSearch!: boolean
  @Prop() hideHeaderShoppingCart!: boolean
  @State klook!: Data.Klook
  @Getter isGuestCheckout!: boolean
  @authModule.State user!: Data.User

  isRecommendationVisible = false

  get market() {
    return this.klook.market
  }

  get siteConfig() {
    return {
      ...this.klook.websiteConfig,
      ...this.klook.utilConfig
    }
  }

  get isSearchVisible() {
    return !this.hideHeaderSearch && this.siteConfig.search
  }

  get isShoppingCartVisible() {
    return !this.hideHeaderShoppingCart && this.siteConfig.shopping_cart
  }

  get aid() {
    return this.$cookies.get('aid')
  }

  get ismp() {
    return !!this.klook.platformMp
  }

  loginSuccess() {
    this.$store.dispatch('auth/getProfile')
  }
}
