import queryString from 'querystring'
import Vue from 'vue'
import xssFilters from 'xss-filters'
import { RootState } from '~/store'
import { languageIsoMap } from '~/share/data/language'
import localesConf from '~/locales.config'

interface LanguageLink {
  hid: string
  rel: string
  href: string
  hreflang?: string
}

/**
 * 生成多语言链接
 * example:
 * <link rel="canonical" href="https://www.klook.com/zh-CN/faq/">
 * <link rel="alternate" href="https://www.klook.com/faq/" hreflang="x-default">
 * <link rel="alternate" href="https://www.klook.com/ja/faq/" hreflang="x-default">
 */
export function genCanonicalLinks(vm: Vue, i18nPath: string, whitelist: string[]) {
  const language = vm.$i18n.locale
  const originalPath = i18nPath.replace(`/${language}`, '')
  const { supportLanguages } = (vm.$store.state as RootState).klook
  const prefix = process.env.BASE_LINK || `https://${vm.$store.state.klook.host}` || 'https://www.klook.com'
  // 根据path是否匹配到language得出canonicalPath
  const canonicalFullPath = i18nPath.includes(`/${language}`) ? `${prefix}/${language}${originalPath}` : `${prefix}${originalPath}`

  // eslint-disable-next-line prefer-const
  let [canonicalPath, canonicalQuery] = canonicalFullPath.split('?')

  try {
    if (whitelist.length > 0) {
      // 配置白名单，？后参数过滤删除
      const _obj = queryString.parse(canonicalQuery)
      let _newCanonicalQuery = '' // 过滤后的query字符串

      Object.keys(_obj).forEach((key) => {
        if (!whitelist.includes(key)) {
          delete _obj[key]
        }
      })

      _newCanonicalQuery = queryString.stringify(_obj)

      if (_newCanonicalQuery) {
        canonicalPath += `?${_newCanonicalQuery}`
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('canonical whitelist err:' + err)
  }
  const links: LanguageLink[] = [
    {
      hid: 'canonical',
      rel: 'canonical',
      href: canonicalPath
    }
  ]

  supportLanguages.forEach((language) => {
    let iso = languageIsoMap[language] || language
    let href = `${prefix}/${language}${originalPath}`

    if (language === localesConf.fallbackLocale) {
      iso = 'x-default'
      href = `${prefix}${originalPath}`
    }

    links.push({
      hid: `ah-${iso}`,
      rel: 'alternate',
      href,
      hreflang: iso
    })

    // @NOTE: 特殊处理一下
    if (language === 'zh-TW') {
      links.push({
        hid: 'ah-zh-Hant',
        rel: 'alternate',
        href,
        hreflang: 'zh-Hant'
      })
    }
  })

  return links
}

/**
 * 生成桌面端头信息
 */
function genDesktopHead(vm: Vue) {
  return {
    meta: [
      // @NOTE: 让 IE 使用最新内核 或者 Chrome 内核来渲染页面
      vm.$setMeta({
        name: 'X-UA-Compatible',
        content: 'IE=edge,chrome=1'
      }),
      // @NOTE: 国产双内核浏览器使用 Webkit 内核来渲染页面
      vm.$setMeta({
        name: 'renderer',
        content: 'webkit'
      }),
      vm.$setMeta({
        name: 'viewport',
        content: 'width=device-width, initial-scale=1'
      })
    ],
    link: [],
    script: []
  }
}

/**
 * 生成移动端头信息
 */
export function genMobileHead(vm: Vue) {
  return {
    meta: [
      vm.$setMeta({
        name: 'viewport',
        content: 'initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, viewport-fit=cover'
      }),
      vm.$setMeta({
        name: 'apple-mobile-web-app-capable',
        content: 'yes'
      }),
      vm.$setMeta({
        name: 'apple-touch-fullscreen',
        content: 'yes'
      }),
      vm.$setMeta({
        name: 'format-detection',
        content: 'telephone=no,email=no'
      })
    ],
    link: [],
    script: []
  }
}

type PaltformHeadMap = Record<Data.Platform, Function>
const platformHeadMap: PaltformHeadMap = {
  desktop: genDesktopHead,
  mobile: genMobileHead
}

export function genCommonDataLayer(vm: Vue) {
  const { meta = {} } = vm.$route

  const { currency, ipCountry, backendLanguage } = vm.$store.state.klook

  const deviceId = xssFilters.inHTMLData(vm.$cookies.get('device_id_new'))

  const data: any = {}

  if (meta.gaPageName) {
    Object.assign(data, {
      Currency: currency,
      IPLocationCity: undefined,
      IPLocationCountry: ipCountry,
      Language: backendLanguage,
      Page: meta.gaPageName
    })
  }

  if (deviceId) {
    data.DeviceId = deviceId
  }

  return data
}

export function getCommonScript(klook: Data.Klook) {
  if (klook.market === 'global') {
    return [
      {
        innerHTML: '!function(e){"function"==typeof define&&define.amd?define(e):e()}((function(){"use strict";window.precolletion=[],["error","unhandledrejection"].forEach((e=>{window.addEventListener(e,(function(n){window.precolletion.push({eventName:e,event:n})}))}))}));',
        type: 'text/javascript',
        pbody: true
      },
      {
        innerHTML: '!function(a,b,c,d,e,f){a.ddjskey=e;a.ddoptions=f||null;var m=b.createElement(c),n=b.getElementsByTagName(c)[0];m.async=1,m.src=d,n.parentNode.insertBefore(m,n)}(window,document,"script","https://js.datadome.co/tags.js","1A2CDFCDF412CD9D3C93BB2E078906", { ajaxListenerPath: true });',
        type: 'text/javascript',
        'data-dome': 1,
        pbody: true
      },
      {
        src: `https://www.googletagmanager.com/gtm.js?id=${process.env.GTM_ID}`,
        crossorigin: 'anonymous',
        async: true
      }
    ]
  }
  return []
}

export function getCommonNoScript(klook: Data.Klook) {
  if (klook.market === 'global') {
    return [
      {
        hid: 'gtm-noscript',
        innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.GTM_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        pbody: true
      }
    ]
  }
  return []
}

/**
 * 生成常见的头
 */
export function genCommonHead(vm: Vue) {
  const language = vm.$i18n.locale as Data.Language
  const platform = vm.$store.state.klook.platform as Data.Platform
  const platformMp = vm.$store.state.klook.platformMp

  const platformHead = platformHeadMap[platform](vm)

  const commonDataLayer = genCommonDataLayer(vm)

  return {
    htmlAttrs: { lang: languageIsoMap[language] || language },
    bodyAttrs: {
      'data-spm-page': vm.$route.meta.trackedPageName,
      class: platformMp ? 'klook-platform-mp ' + (vm.$route.meta.bodyClass || '') : vm.$route.meta.bodyClass
    },
    title: vm.$t('global.seo.title'),
    meta: [
      { charset: 'utf-8' },
      vm.$setMeta({
        name: 'keywords',
        content: vm.$t('global.seo.keywords') as string
      }),
      vm.$setMeta({
        name: 'description',
        content: vm.$t('global.seo.description') as string
      }),
      vm.$setMeta({
        name: 'msvalidate.01',
        content: 'F9AF19DB43086103E6C6EF03373C2EEF'
      }),
      vm.$setMeta({
        name: 'author',
        content: vm.$t('global.seo.author') as string
      }),
      vm.$setMeta({
        name: 'google-site-verification',
        content: 'm-vUVyQRvMiIbMOyDm-13PmkhgZHQ0O3JqRVkfSB7uw'
      }),
      vm.$setMeta({
        name: 'yandex-verification',
        content: '794dfaf8476c38c4'
      }),
      vm.$setMeta({
        property: 'fb:pages',
        content: '1437638383172374'
      }),
      ...platformHead.meta
    ],
    link: [
      {
        rel: 'dns-prefetch',
        href: 'https://res.klook.com'
      },
      {
        rel: 'dns-prefetch',
        href: 'https://cdn.klook.com'
      },
      // Give up IE, Cross Browser Favicons: https://www.kevinleary.net/cross-browser-favicons/
      {
        rel: 'icon',
        type: 'image/png',
        href: 'https://cdn.klook.com/s/dist_web/favicons/favicon-32x32.png'
      },
      ...platformHead.link
    ],
    script: [
      vm.$insetDataLayer(commonDataLayer),
      ...platformHead.script,
      ...getCommonScript(vm.$store.state.klook)
    ],
    noscript: [
      ...getCommonNoScript(vm.$store.state.klook)
    ],
    __dangerouslyDisableSanitizers: ['script', 'noscript']
  }
}

/**
 * 获取反爬脚本配置
 */
export function getAntiSpiderHead(vm: Vue) {
  if (vm.$store.state.klook.host === 'www.klook.cn') {
    return {}
  }
  return {
    script: [
      // {
      //   hid: 'PX5J5h9CC2',
      //   innerHTML: '(function(){window._pxAppId="PX5J5h9CC2";var p=document.getElementsByTagName("script")[0],s=document.createElement("script");s.async=1;s.src="https://cdn.klook.com/s/tetris/common/1.0.1/px/int-5J5h9CC2.js";p.parentNode.insertBefore(s,p)})();',
      //   pbody: true
      // }
    ]
  }
}
