import Vue from 'vue'
import { getQueryKey, loadScript, parseUrlQuery } from '~/share/utils'
import localStorageEx from '~/assets/scripts/localstorage.js'

const localStorage = localStorageEx()

export async function initAffiliatePartner(vm: any) {
  const aid = getQueryKey('aid', vm.$route.query)
  if (aid) {
    affiliateGa(aid)

    // https://www.usebutton.com/
    // 获取button参数
    if (getQueryKey('affiliate_partner', vm.$route.query) === 'button') {
      // button的参数没有的话也要传空
      const btnRefObj = {
        affiliate_partner:
          getQueryKey('affiliate_partner', vm.$route.query) || '',
        content: ''
      }
      await loadButtonSdk()
      const btnRef = window.bttnio('getReferrerToken') || ''
      btnRefObj.content = btnRef

      let aidExtra = vm.klook.affiliateConf.aid_extra_info
      const cookie_duration = vm.klook.affiliateConf.cookie_duration || 30
      const expires = new Date(Date.now() + cookie_duration * 24 * 60 * 60 * 1000)
      aidExtra = { ...aidExtra, ...btnRefObj }
      setAidExtraCookies(vm, aidExtra, expires)
    }
  }
}

export function setAidExtraCookies(vm: Vue, aidExtra: any, expires?: any) {
  const aidExtraJson = JSON.stringify(aidExtra)
  vm.$cookies.set('aid_extra', aidExtraJson, {
    path: '/',
    expires: expires || new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
  })
}

async function loadButtonSdk() {
  window.ButtonWebConfig = {
    applicationId: 'app-46e96d02fcd86a4f'
  }

  await loadScript('https://web.btncdn.com/v1/button.js')
}

function affiliateGa(aid: string) {
  if (window.ga) {
    window.ga('send', 'event', 'affiliate', 'visit', aid)
  }
}

/**
 * aid为13925 (mastercard卡组织合作伙伴)且链接带了cardIssueCountry参数，写个localStorage，在登录注册时自动调个接口领优惠券
 * @param vm
 */
export function initMasterCardParams(vm: any) {
  if (vm.$cookies.get('aid') && vm.$cookies.get('aid') === 13925) {
    const { cardIssueCountry } = parseUrlQuery()
    cardIssueCountry &&
      localStorage.setItem('master_country_code', cardIssueCountry, 24 * 3600)
  }
}
